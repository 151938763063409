import { Component, createRef, RefObject } from "preact";
import type { JSXInternal } from "preact/src/jsx";
type CSSProperties = JSXInternal.CSSProperties;
import ChatFrame from "./chat-frame";
import ChatFloatingButton from "./chat-floating-button";
import ChatTitleMsg from "./chat-title-msg";
import Api from "./api";
import {
  desktopTitleStyle,
  desktopWrapperStyle,
  mobileOpenWrapperStyle,
  mobileClosedWrapperStyle,
  desktopClosedWrapperStyleChat,
} from "./style";
import { IBotInfo, IConfiguration, IMessage } from "../typings";
import { getBotInfo, getMessageHistory } from "./util";
import { shrinkWidget, useExpandWidget } from "./util/widgetAnimations";
import Header from "./Header";
import Webview, {ViewMode} from "./components/Webview";

function Resizer({ chatFrameRef }: { chatFrameRef: RefObject<HTMLDivElement> }) {
  const grabSpace = 6;
  const topResize: CSSProperties = {
    position: "absolute",
    width: "100%",
    height: grabSpace,
    top: -grabSpace,
    cursor: "n-resize",
  };

  const leftResize: CSSProperties = {
    position: "absolute",
    width: grabSpace,
    height: "100%",
    left: -grabSpace,
    cursor: "e-resize",
  };

  const topLeftResize: CSSProperties = {
    position: "absolute",
    width: grabSpace * 2,
    height: grabSpace * 2,
    top: -grabSpace,
    left: -grabSpace,
    cursor: "nw-resize",
    zIndex: 3,
  };

  let startY: number, startX: number;
  let startWidth: number, startHeight: number;

  const resize = (e: MouseEvent, resizeX = true, resizeY = true) => {
    if (!chatFrameRef.current) {
      return;
    }

    // in firefox, allow dragging over the iframe so dragging isn't interrupted when the mouse is over the iframe
    const iframe = document.querySelector<HTMLIFrameElement>("iframe");
    iframe.style.pointerEvents = "none";

    startX = e.clientX;
    startY = e.clientY;
    const chatFrameStyle = getComputedStyle(chatFrameRef.current);
    startWidth = parseInt(chatFrameStyle.width, 10);
    startHeight = parseInt(chatFrameStyle.height, 10);

    const maxWidth = parseFloat(chatFrameStyle.maxWidth),
      maxHeight = parseFloat(chatFrameStyle.maxHeight),
      minWidth = parseFloat(chatFrameStyle.minWidth),
      minHeight = parseFloat(chatFrameStyle.minHeight);

    const drag = (e: MouseEvent) => {
      // prevent default to not select text when dragging. Selecting text stops the drag when it's inside the element
      e.preventDefault();

      // no mouse buttons being pressed
      if (e.buttons === 0) {
        removeEventListeners();
        return;
      }

      if (resizeX) {
        const newWidth = startWidth + startX - e.clientX;
        if (newWidth < maxWidth && newWidth > minWidth) {
          chatFrameRef.current.style.width = `${newWidth}px`;
        }
      }
      if (resizeY) {
        const newHeight = startHeight + startY - e.clientY;
        if (newHeight < maxHeight && newHeight > minHeight) {
          chatFrameRef.current.style.height = `${newHeight}px`;
        }
      }
    };
    window.addEventListener("mousemove", drag);
    const removeEventListeners = () => {
      window.removeEventListener("mousemove", drag);
      window.removeEventListener("mouseup", removeEventListeners);
      iframe.style.removeProperty("pointer-events");
    };
    window.addEventListener("mouseup", removeEventListeners);
  };

  return (
    <>
      <div
        style={topLeftResize}
        onMouseDown={(e) => {
          resize(e);
        }}
      />
      <div
        style={topResize}
        onMouseDown={(e) => {
          resize(e, false, true);
        }}
      />
      <div
        style={leftResize}
        onMouseDown={(e) => {
          resize(e, true, false);
        }}
      />
    </>
  );
}

export default class Widget extends Component<IWidgetProps, IWidgetState> {
  private readonly chatFrameRef: RefObject<HTMLDivElement>;
  private readonly fullWrapperRef: RefObject<HTMLDivElement>;
  private readonly wrapperRef: RefObject<HTMLDivElement>;
  private readonly webviewRef: RefObject<HTMLDialogElement>;
  private botInfoPromise: Promise<IBotInfo> | undefined;
  private messageHistory: Promiseable<IMessage[] | undefined>;

  constructor() {
    super();

    this.state = {
      frameKey: 0,
      isChatOpen: false,
      pristine: true,
      wasChatOpened: false,
      reload: false,
      webviewMode: "full",
      webviewUrl: "",
      webviewOpen: false
    };

    this.chatFrameRef = createRef<HTMLDivElement>();
    this.fullWrapperRef = createRef<HTMLDivElement>();
    this.wrapperRef = createRef<HTMLDivElement>();
    this.webviewRef = createRef<HTMLDialogElement>();
  }

  componentDidMount() {
    window.webWidgetApi = new Api(this);
    // ! deprecated
    window.botmanChatWidget = window.webWidgetApi;

    if (typeof this.props.conf.init === "function") {
      this.props.conf.init(window.webWidgetApi);
    }

    // if saveMessages (to localstorage) is enabled, don't fetch chat history
    this.messageHistory = !this.props.conf.saveMessages
      ? getMessageHistory(this.props.conf.botId, this.props.conf.userId)
          .catch(console.error)
          .then(() => this.props.conf.startMessages)
      : this.props.conf.startMessages;

    if (
      this.props.conf.botId &&
      !(
        this.props.conf.persistentMenu &&
        this.props.conf.richCardMediaPosition &&
        this.props.conf.richCardMediaSize
      )
    ) {
      this.botInfoPromise = getBotInfo(this.props.conf.botId);
    }

    // Add event listener to receive messages from the iframe
    window.addEventListener('message', this.handleMessageFromIframe);
  }

  componentWillUnmount() {
    // Remove event listener to avoid memory leaks
    window.removeEventListener('message', this.handleMessageFromIframe);
  }

  handleMessageFromIframe = (event: MessageEvent) => {
    const url = new URL(this.props.iFrameSrc);
    // Security check to ensure messages are from the expected origin
    if (event.origin !== url.origin) {
      console.debug(url);
      console.warn('Ignored message from unauthorized origin:', event.origin);
      return;
    }

    const { data } = event;
    if (data && data.type) {
      // a request to open a webview from action url suggestion was received from the Chat iframe
      switch (data.type) {
        case 'WEBVIEW_OPEN':
          const webviewUrl = new URL(data.message.url);
          webviewUrl.searchParams.set('bot-hide', '1');
          this.setState({
            webviewMode: data.message.viewMode,
            webviewUrl:  webviewUrl.toString(),
            webviewOpen:  true
          });
          break;
      }
    }
  };

  render(props: IWidgetProps, state: IWidgetState) {
    const { conf, isMobile } = props;
    const { isChatOpen, pristine, wasChatOpened } = state;

    let wrapperStyle;
    if (!isChatOpen && (isMobile || conf.alwaysUseFloatingButton)) {
      wrapperStyle = mobileClosedWrapperStyle; // closed mobile floating button
    } else if (!isMobile) {
      wrapperStyle =
        isChatOpen || wasChatOpened
          ? isChatOpen
            ? desktopWrapperStyle // desktop mode, button style
            : desktopClosedWrapperStyleChat
          : desktopClosedWrapperStyleChat; // desktop mode, chat style
    } else {
      wrapperStyle = mobileOpenWrapperStyle; // open mobile wrapper should have no border
    }

    // hooks are not supposed to be used in class components, but they work in preact conveniently
    if (this.props.conf.animateWidgetOpen) {
      useExpandWidget(isChatOpen, this.wrapperRef, this.fullWrapperRef);
    }

    return (
      <div
        ref={this.fullWrapperRef}
        class="full-widget-wrapper"
        style={{
          ...wrapperStyle,
          ...(!isChatOpen && { width: "fit-content", height: "fit-content" }),
        }}
      >
        <div
          ref={this.wrapperRef}
          style={{ width: (isMobile ? "100%" : "auto"), position: "absolute", right: "0", ...(isChatOpen && { bottom: "0" }) }}
        >
          {props.conf.enableUserResizing && !isMobile && (
            <Resizer chatFrameRef={this.chatFrameRef} />
          )}
          {/* Open/close button */}
          {(isMobile || conf.alwaysUseFloatingButton) && !isChatOpen ? (
            <ChatFloatingButton onClick={this.toggle} conf={conf} />
          ) : isChatOpen || this.state.wasChatOpened ? (
            isChatOpen ? (
              <Header conf={conf} widget={this} />
            ) : (
              <ChatTitleMsg onClick={this.toggle} conf={conf} />
            )
          ) : (
            <ChatTitleMsg onClick={this.toggle} conf={conf} />
          )}

          {/* Chat IFrame Wrapper */}
          <div
            ref={this.chatFrameRef}
            key="chatframe"
            style={{
              display: isChatOpen ? "block" : "none",
              minWidth: !isMobile ? "260px" : conf.mobileWidth,
              minHeight: !isMobile ? "300px" : conf.mobileHeight,
              width: (!isMobile ? conf.desktopWidth : conf.mobileWidth) || "auto",
              height:
                (isMobile ? `calc(100vh - ${desktopTitleStyle.height})` : conf.desktopHeight) ||
                "auto",
              maxWidth: !isMobile ? "60vw" : undefined,
              maxHeight: !isMobile ? "80vh" : undefined,
              overflow: "hidden",
              borderRadius: "0 0 8px 8px",
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
              background: conf.darkMode ? "#1f1f1f" : "white",
            }}
          >
            {pristine
              ? null
              : (isChatOpen || wasChatOpened) && (
                  <ChatFrame
                    key={state.frameKey}
                    messageHistory={this.messageHistory}
                    reload={state.reload}
                    botInfo={this.botInfoPromise}
                    {...this.props}
                  />
                )}
          </div>
          <Webview ref={this.webviewRef} isOpen={state.webviewOpen} url={state.webviewUrl} viewMode={state.webviewMode} onClose={this.onWebviewClose}></Webview>
        </div>
      </div>
    );
  }

  checkServerStatus = async (chatServer: RequestInfo) => {
    try {
      /**
       * if the server responds at all, the server is online.
       * if the server is offline, there will be an error caught
       * checking the status is not possible with "no-cors"
       * if the status needs to be checked, this server would have to be allowed in the interpreter
       * and check the status for code 200
       */
      await fetch(chatServer, { mode: "no-cors" });
      return true;
    } catch {
      return false;
    }
  };

  onWebviewClose = () => {
    this.setState({
      webviewUrl: '',
      webviewOpen:  false
    });
  };

  scrollToBottom = () => {
    (document.getElementById("chatBotManFrame") as HTMLIFrameElement)?.contentWindow?.postMessage(
      "scrollToBottom",
      "*"
    );
  };

  reload = (e?: MouseEvent) => {
    // changing key on chat frame to rerender it and restart the chatbot conversation
    const reloadFrame = () => {
      this.setState({
        frameKey: this.state.frameKey + 1,
        reload: true,
        webviewUrl: '',
        webviewOpen: false
      });
    };
    if (import.meta.env.DEV && !e?.shiftKey) {
      console.debug("Reloading chatbot, stopping ws.");
      (document.getElementById("chatBotManFrame") as HTMLIFrameElement)?.contentWindow?.postMessage(
        "stopws",
        "*"
      );
      let timeout: number;
      const wsStoppedHandler = (e: MessageEvent<any>) => {
        if (e.data === "wsstopped") {
          reloadFrame();
          clearTimeout(timeout);
        }
      };
      window.addEventListener("message", wsStoppedHandler, { once: true });
      timeout = setTimeout(() => {
        window.removeEventListener("message", wsStoppedHandler);
        console.error("Chatbot reload timeout. Never got 'wsstopped' message.");
        reloadFrame();
      }, 1000) as any as number;
    } else {
      reloadFrame();
    }
  };

  reloadAsync = () => {
    this.reload();

    return new Promise<void>((res, rej) => {
      let timeout: number;

      const handler = (e: MessageEvent<any>) => {
        if (e.data === "chatReady") {
          clearTimeout(timeout);
          res();
          window.removeEventListener("message", handler);
        }
      };
      window.addEventListener("message", handler);

      timeout = setTimeout(() => rej("timeout"), 10000) as any as number;
    });
  };

  toggle = () => {
    return new Promise<void>(async (res) => {
      if (this.state.isChatOpen && this.props.conf.animateWidgetOpen) {
        await shrinkWidget(this.wrapperRef.current, this.fullWrapperRef.current);
      }

      this.setState(
        {
          pristine: false,
          isChatOpen: !this.state.isChatOpen,
          wasChatOpened: this.state.wasChatOpened || this.state.isChatOpen,
        },
        res
      );
      this.scrollToBottom();
    });
  };

  // no need to call expandWidget on open. It is called in the render method since expanding only works if there is enough content (the chat frame) to display
  open = () => {
    return new Promise<void>((res) => {
      this.setState(
        {
          pristine: false,
          isChatOpen: true,
          wasChatOpened: true,
        },
        res
      );
      this.scrollToBottom();
    });
  };

  close = () => {
    return new Promise<void>(async (res) => {
      if (this.state.isChatOpen && this.props.conf.animateWidgetOpen) {
        await shrinkWidget(this.wrapperRef.current, this.fullWrapperRef.current);
      }

      this.setState(
        {
          pristine: false,
          isChatOpen: false,
        },
        res
      );
    });
  };
}

interface IWidgetState {
  frameKey: number;
  isChatOpen: boolean;
  pristine: boolean;
  wasChatOpened: boolean;
  reload: boolean;
  webviewMode: ViewMode;
  webviewUrl: string;
  webviewOpen: boolean;
}

interface IWidgetProps {
  iFrameSrc: string;
  conf: IConfiguration;
  isMobile: boolean;
}

declare global {
  interface Window {
    attachEvent: Function;
    webWidgetApi: Api;
    // ! deprecated
    botmanChatWidget: Api;
  }
}
